.button-call-accept.ant-btn-primary:not(:disabled) {
  background: var(--semantic-green) !important;
  color: white !important;
}

.button-call-accept.ant-btn-primary:not(:disabled):hover {
  background: var(--semantic-green) !important;
  color: white !important;
  opacity: 0.5 !important;
}

.button-call-hang-up.ant-btn-primary:not(:disabled) {
  background: var(--semantic-red) !important;
  color: white !important;
}

.button-call-hang-up.ant-btn-primary:not(:disabled):hover {
  background: var(--semantic-red) !important;
  color: white !important;
  opacity: 0.5 !important;
}

.button-call-mute.ant-btn-primary:not(:disabled) {
  border: 1px solid var(--neutral-grey-mid) !important;
  background: var(--neutral-grey) !important;
  color: var(--tpl-navy) !important;
}

.button-call-mute.ant-btn-primary:not(:disabled):hover {
  border: 1px solid var(--neutral-grey-mid) !important;
  background: var(--neutral-grey) !important;
  color: var(--tpl-navy) !important;
  opacity: 0.5 !important;
}
